export const MONTH_TH_FULL_NAME = {
  0: 'มกราคม',
  1: 'กุมภาพันธ์',
  2: 'มีนาคม',
  3: 'เมษายน',
  4: 'พฤษภาคม',
  5: 'มิถุนายน',
  6: 'กรกฎาคม',
  7: 'สิงหาคม',
  8: 'กันยายน',
  9: 'ตุลาคม',
  10: 'พฤศจิกายน',
  11: 'ธันวาคม',
}

export const MONTH_TH_SHORT_NAME = {
  0: 'ม.ค.',
  1: 'ก.พ.',
  2: 'มี.ค.',
  3: 'เม.ย.',
  4: 'พ.ค.',
  5: 'มิ.ย.',
  6: 'ก.ค',
  7: 'ส.ค.',
  8: 'ก.ย.',
  9: 'ต.ค.',
  10: 'พ.ย.',
  11: 'ธ.ค.',
}

export const getFullMonthTH = month => {
  return MONTH_TH_FULL_NAME[month - 1]
}

export const getShortMonthTH = month => {
  return MONTH_TH_SHORT_NAME[month - 1]
}
