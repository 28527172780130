<template>
  <div>
    <v-img
      :class="cssIcon"
      contain
      :src="name"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    nameIcon: {
      type: [String, Number],
      default: '',
    },
    cssIcon: {
      type: [String, Number],
      default: '',
    },
    width: {
      type: [Number, String],
      default: 17,
    },
    height: {
      type: [Number, String],
      default: 17,
    },
    isImage: {
      type: Boolean,
      default: false,
    },
    isSvg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    name() {
      if (this.isSvg) {
        return require(`@/assets/${this.nameIcon}`)
      }
      return this.isImage
        ? this.$appConfig.serveConfig.SERVICE_URL_IMAGE + this.nameIcon
        : require(`@/assets/${this.nameIcon}.png`)
    },
  },
}
</script>

<style scoped></style>
