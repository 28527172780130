import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthServices from '@/services/AuthServices'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'News',
    component: () => import('../views/Main/News/News.vue'),
  },
  {
    path: '/news-detail/:id',
    name: 'NewsDetail',
    props: true,
    component: () => import('../views/Main/News/NewsDetail'),
  },
  {
    path: '/content/:id',
    props: true,
    name: 'Content',
    component: () => import('../views/Main/Moomall-Content/ContentList'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Main/Moomall-Content/ContactUs'),
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import('../views/Main/Gallery/GalleryMain'),
  },
  {
    path: '/content-detail/:id',
    name: 'ContentDetail',
    props: true,
    component: () => import('../views/Main/Moomall-Content/ContentDetail'),
  },
  {
    path: '/news-list',
    name: 'NewsList',
    component: () => import('../views/Main/News/NewsList'),
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Main/Search/Search'),
  },
  {
    path: '/search/searchList/:searchName',
    name: 'SearchList',
    props: true,
    component: () => import('../views/Main/Search/SearchList'),
  },
  {
    path: '/events/maldives',
    name: 'Event',
    props: true,
    component: () => import('../views/Main/Event/Maldives'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { requiresAuth: true },
    redirect: 'dashboard/news',
    component: () => import('../views/Admin/Home'),
    children: [
      {
        path: 'news',
        name: 'NewsAdmin',
        component: () => import('../views/Admin/News/News'),
      },
      {
        path: 'footers',
        name: 'FootersAdmin',
        component: () => import('../views/Admin/Footers/Footers'),
      },
      {
        path: 'contentlist',
        name: 'Contentlist',
        component: () => import('../views/Admin/Content/ContentList'),
      },
      {
        path: 'content',
        name: 'ContentAdmin',
        component: () => import('../views/Admin/Content/ContentDetail'),
      },
      {
        path: 'gallery',
        name: 'GalleryAdmin',
        component: () => import('../views/Admin/Gallery/Gallery'),
      },
      {
        path: 'maldives',
        name: 'DashboardMaldives',
        component: () => import('../views/Main/Event/Maldives'),
      },
      {
        path: 'course',
        name: 'CoursePhone',
        component: () => import('../views/Admin/Course/PhoneList'),
      },
    ],
  },
  {
    path: '/admin',
    redirect: '/admin/login',
    meta: { Login: true },
    name: 'Admin',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/Admin/Login/LoginPage'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

async function tokenIsValid() {
  try {
    const resp = await AuthServices.CheckToken()

    if (resp.status == 200) {
      return true
    }
  } catch (err) {
    return false
  }

  return false
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (await tokenIsValid()) {
      next()
      return
    }
    next('/admin')
  } else {
    next()
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.Login)) {
    if (!(await tokenIsValid())) {
      next()
      return
    }

    next('/dashboard')
  } else {
    next()
  }
})
export default router
