export const PacerStore = {
  namespaced: true,
  actions: {},
  state: {
    pacerLevel: null,
    mallMemberTokenKey: null,
    userMobile: null,
  },
  mutations: {
    setPacerLevel: (state, { pacerLevel }) => {
      state.pacerLevel = pacerLevel
    },
    setMallMemberTokenKey: (state, { mallMemberTokenKey }) => {
      state.mallMemberTokenKey = mallMemberTokenKey
    },
    setUserMobile: (state, { userMobile }) => {
      state.userMobile = userMobile
    },
  },
  getters: {
    PacerLevel: state => state.pacerLevel,
    MallMemberTokenKey: state => state.mallMemberTokenKey,
    Mobile: state => state.userMobile,
  },
}
