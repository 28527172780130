import { apiServices } from '@/utils/request'

export default {
  getEvents() {
    return apiServices.get('/event/')
  },
  getEventDetail(name, { inviteCode, dateStart, dateEnd, mallMemberToken }) {
    return apiServices.get(`/event/${name}`, {
      params: {
        inviteCode,
        dateStart,
        dateEnd,
        mall_member_token_key: mallMemberToken,
      },
    })
  },
  getEventDetailAdmin(name, { inviteCode, dateStart, dateEnd }) {
    return apiServices.get(`/event/${name}/admin`, {
      params: {
        inviteCode,
        dateStart,
        dateEnd,
      },
    })
  },
}
