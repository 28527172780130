export const MoomallContentStore = {
  namespaced: true,
  actions: {},
  state: {
    MoomallContent: null,
  },
  mutations: {
    getMoomallContent: (state, data) => {
      state.MoomallContent = data
    },
  },
  getters: {
    MoomallContent: state => state.MoomallContent,
  },
}
