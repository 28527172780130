import { mapMutations } from 'vuex'

export const FormMixin = {
  data() {
    return {
      search: '',
      editorConfig: {
        allowedContent: true,
        toolbar: [
          { name: 'styles', items: ['Styles'] },
          {
            name: 'document',
            items: ['Source', '-', 'Undo', 'Redo'],
          },
          {
            name: 'basicstyles',
            items: ['Bold', 'Italic', 'Strike', '-'],
          },
          {
            name: 'paragraph',
            items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent'],
          },
          { name: 'links', items: ['Link'] },
        ],
      },
      radioGroup: 1,
      listType: [
        { title: 'Link Youtube', value: 1 },
        { title: 'Long Page Image', value: 2 },
      ],
      loading: false,
      dialog1: false,
      conetnetListDialog: false,
      page: 1,
      pageCount: 0,
      pageSize: 10,
      totalPages: 0,
      items: [
        {
          id: 0,
          name: 'deduct tag',
        },
        {
          id: 1,
          name: 'New',
        },
      ],
      sortBy: [],
      footerId: [
        {
          id: 1,
          name: 'footer menu 1',
        },
        {
          id: 2,
          name: 'footer menu 2',
        },
        {
          id: 3,
          name: 'footer menu 3',
        },
      ],
      files: [],
      postFormData: new FormData(),
      fileSingle: null,
      file: '',
      pdf_files: null,
      pdf_file: '',
      news: [],
      product: {
        body_en: '',
        body_th: '',
        is_new: null,
        title_en: '',
        title_th: '',
      },
      footer: {
        sort: null,
        title_en: '',
        title_th: '',
      },
      footers: [],
      content_list: {
        title_th: '',
        title_en: '',
        body_th: '',
        body_en: '',
        content_id: null,
      },
      disableNotAllow: null,
      content_lists: [],
      content_details: [],
      content_detail: {
        title_th: '',
        title_en: '',
        body_th: '',
        body_en: '',
        bullet_title_th: '',
        bullet_title_en: '',
        video_url: '',
        parent_id: 0,
        footer_menu_id: null,
        sort: null,
      },
      valid: true,
      validContentList: true,
      activeItem: null,
      modal: null,
      username: '',
      password: '',
      fileRules: [
        v => !!v || 'File is required',
        v => (v && v.length > 0) || 'File is required',
        files =>
          !files ||
          !files.some(file => file.size > 3e6) ||
          'Image size should be less than 3MB!',
      ],
      fileRulesNews: [
        v => !!v || 'File is required',
        file =>
          !file || file.size < 3e6 || 'Image size should be less than 3MB!',
      ],
      fileSizeRules: [
        file =>
          !file || file.size < 3e6 || 'Image size should be less than 3MB!',
      ],
      requiredNumberRules: [
        v => !!v || 'Required',
        v => /^\d+$/.test(v) || 'Must be a number',
        v =>
          (v && v.length >= 0 && v <= 999) ||
          'Number has to be between 0 and 999',
      ],
      sortRules: [
        v => !!v || 'Required',
        v => /^\d+$/.test(v) || 'Must be a number',
      ],
      textAreaRules: [
        v => !!v || 'Required',
        v => v.length <= 500 || 'Max 500 characters',
      ],
      VideoRules: [
        v => !!v || 'Required',
        v => v.length <= 50 || 'Max 50 characters',
      ],
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => "The email and password you entered don't match",
      },
      usernameRules: [v => !!v || 'Username is required'],
      bulletTitleRules: [
        v => !!v || 'Required',
        v => (v && v.length <= 150) || 'Max 150 characters',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Password must be more than 8 characters',
      ],
      // passwordRules: [
      //   v => !!v || 'Password is required',
      //   v => (v && v.length >= 5) || 'Password must have 5+ characters',
      //   v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
      //   v => /(?=.*\d)/.test(v) || 'Must have one number',
      //   v => /([!@$%])/.test(v) || 'Must have one special character [!@#$%]'
      // ]
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  methods: {
    ...mapMutations({ removeToken: 'AuthStore/removeToken' }),
    next() {
      this.readData()
    },
    uploadImages() {
      this.file = this.files
    },
    uploadImage() {
      this.file = this.fileSingle
    },
    uploadImageMultiple() {
      for (var i = 0; i < this.files.length; i++) {
        this.postFormData.append('image', this.files[i])
      }
    },
    uploadPDFImages() {
      this.pdf_file = this.pdf_files
    },
    close() {
      this.dialog1 = false
      this.resetData()
    },
    closeContentList() {
      this.conetnetListDialog = false
      this.resetContentList()
    },
    resetValidate() {
      this.$refs.form.resetValidation()
    },
    switchDisable(val, data) {
      if (!data) {
        return (this.disableNotAllow = null)
      }

      return (this.disableNotAllow = val)
    },
    switchType(val) {
      switch (val) {
        case 1:
          this.pdf_files = null
          this.pdf_file = ''
          break
        case 2:
          this.content_detail.video_url = ''
          break
        default:
        // code block
      }
    },
  },
}
