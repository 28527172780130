<template>
  <div>
    <v-container>
      <span
        v-if="searchList"
        class="text-title"
        v-html="
          $i18n.locale === 'th_TH'
            ? $t(`search.searchNotFound`)
            : $t(`search.searchNotFound`)
        "
      >
      </span>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'BaseLoading',
  props: {
    searchList: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.text-title {
  color: #707070;
  display: flex;
  justify-content: center;
}
</style>
