<template>
  <!-- NOTE: ปรับ mb-5 pb-5 ดันส่วนล่างขึ้นมา -->
  <div v-if="itemList.length > 1" class="mt-2 mb-5 pb-5">
    <v-card
      class="mb-2 card-tab ma-0"
      :elevation="checkBorder(item.id)"
      v-for="item in itemList"
      :key="item.id"
      @click="selectItem(item)"
    >
      <div class="d-flex pa-4">
        <BaseImage
          :pathImage="item.image_id"
          :width="isSquare ? 100 : 147"
          :height="100"
          cssImage="mr-2 rounded-lg"
          :isVideo="item.video_url"
        />
        <div class="box-right">
          <div
            class="text-title"
            v-html="
              $i18n.locale === 'th_TH'
                ? item.bullet_title_th
                : item.bullet_title_en
            "
          ></div>
          <div v-if="isVideo && sellerSku" class="text-subtitle-clock">
            <v-icon small>mdi-clock-outline</v-icon>
            {{ formartDuration(item.duration) }} นาที
          </div>
          <div
            v-if="isVideo"
            class="text-subtitle-clock"
            style="position: absolute;bottom:2ch;"
          >
            <v-chip v-if="checkLable(item.id)" class="active">{{
              $i18n.locale === 'th_TH'
                ? $t(`activeCourse.Active`)
                : $t(`activeCourse.Active`)
            }}</v-chip>
          </div>

          <v-card-actions class="px-0" v-if="!isVideo">
            <span v-if="!item.video_url == ''">
              <BaseIcon nameIcon="icon-video" height="30" width="30"
            /></span>
            <!-- TODO: รอ custom api ให้ส่งค่าจำนวน video มา  -->
            <!-- <span
              v-if="!item.video_url == ''"
              style="font-size: 14px;"
              class="pl-2"
              >1 วีดีโอ</span
            > -->
            <v-spacer></v-spacer>
            <div v-if="isEpOnly(item)">
              <v-chip class="text-badge mr-1" color="#FFDB7C">
                {{ labelTag('EP_ONLY') }}
              </v-chip>
            </div>
            <div v-if="isJpAndAbove(item)">
              <v-chip class="text-badge">
                {{ labelTag('JP_AND_ABOVE') }}
              </v-chip>
            </div>
            <div v-if="!isEpOnly(item) && !isJpAndAbove(item)">
              <BaseButtonShare
                v-if="!isNewsList && webShareApiSupported"
                icon="icon-share-nav-new"
                :postfix="`content-detail/${item.id}`"
                :isExclusive="isEpOnly(item) || isJpAndAbove(item)"
                alertMsg="ไม่สามารถแชร์ได้ เนื่องจากเนื้อหานี้มีการจำกัดสิทธิ์ในการเข้าถึง"
                :small="true"
                css="mr-2"
              />
            </div>
          </v-card-actions>
          <div class="d-flex align-content-end flex-wrap" v-if="isNewsList">
            <BaseIcon nameIcon="Icon-calendar" :width="12" cssIcon="ma-1" />

            <div
              class="subtitle-text grey--text text--darken-2 my-auto"
              v-text="created_at(item.created_at)"
            ></div>
            <v-chip
              class="my-auto ml-auto float-right subtitle-text"
              color="#D11515"
              x-small
              dark
              v-if="item.is_new"
            >
              {{ $t('mooMallNews.new') }}
            </v-chip>
          </div>
        </div>
      </div>
    </v-card>
    <BaseDialog @activeDialog="activeDialog" :dialog="dialog" :label="label" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PACER_LEVEL from '@/constants/pacerLevel'
import { VISIBLE_LABELS, VISIBLE_ALERT } from '@/constants/visibleChoices'
import * as dayjs from 'dayjs'
var duration = require('dayjs/plugin/duration')
import BaseDialog from '@/components/Main/BaseDialog'
dayjs.extend(duration)

export default {
  name: 'CardInformationListVideo',
  emits: ['update:activeContant'],
  components: { BaseDialog },
  props: {
    activeId: {
      type: Number,
      default: null,
    },
    itemList: {
      type: [Array, Object],
      default: () => [],
    },
    isGoto: {
      type: String,
      default: 'ContentDetail',
    },
    isNewsList: {
      type: Boolean,
      default: false,
    },
    isSquare: {
      type: Boolean,
      default: false,
    },
    isVideo: {
      type: Boolean,
      default: false,
    },
    isContant: {
      type: Boolean,
      default: false,
    },
    isContantSub: {
      type: Boolean,
      required: true,
    },
    sellerSku: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      label: '',
    }
  },
  methods: {
    activeDialog() {
      this.dialog = false
    },
    showDialog(dialog, label) {
      this.label = label
      this.dialog = dialog
    },
    formartDuration(duration) {
      return dayjs.duration(duration, 'seconds').format('mm:ss')
    },
    ...mapGetters({ PacerLevel: 'PacerStore/PacerLevel' }),
    selectItem(item) {
      if (this.isJpAndAbove(item) && this.PacerLevel() < PACER_LEVEL.JP) {
        return this.showDialog(true, VISIBLE_ALERT.JP_AND_ABOVE)
      }
      if (this.isEpOnly(item) && this.PacerLevel() !== PACER_LEVEL.EP) {
        return this.showDialog(true, VISIBLE_ALERT.EP_ONLY)
      }
      if (item.seller_sku == null) {
        this.activeContant = item
        this.$emit('activeContant', item)
        this.scrollToTop()
      } else {
        if (!this.isContant && !this.isContantSub) {
          this.showDialog(
            true,
            'ต้องซื้อคอร์สเรียนนี้ก่อน จึงจะสามารถดูเนื้อหาในคอร์สได้',
          )
        } else {
          this.activeContant = item
          this.$emit('activeContant', item)
        }
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    checkBorder(id) {
      if (this.activeId === id) {
        return '1'
      }
      return '0'
    },
    checkLable(id) {
      if (this.activeId === id) {
        return true
      } else {
        return false
      }
    },
    created_at(local) {
      var date = new Date(local)
      var dateFormat = date.toLocaleDateString(
        this.$i18n.locale === 'th_TH' ? 'th-TH' : 'en-EN',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        },
      )
      return dateFormat
    },
    isJpAndAbove(item) {
      return item.is_visible_ep && item.is_visible_sp && item.is_visible_jp
    },
    isEpOnly(item) {
      return item.is_visible_ep && !item.is_visible_sp && !item.is_visible_jp
    },
    popupForbiddenItem(icon, title) {
      this.$swal.fire({
        icon: icon,
        title: title,
      })
    },
    labelTag(key) {
      return VISIBLE_LABELS[`${key}`]
    },
  },
  computed: {
    webShareApiSupported() {
      return navigator.share
    },
  },
}
</script>

<style scoped>
.v-application .elevation-1 {
  border-radius: 25px;
  border: 2px solid #da3d3c;
}
.card-tab {
  border-radius: 25px;
}
.text-title {
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 23px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-line-clamp: min(1, 2);
  -webkit-line-clamp: max(1, 2);
  -webkit-box-orient: vertical;
}

.text-subtitle-clock {
  font-size: 14px;
  letter-spacing: 0.114px;
  color: #707070;
  width: 100%;
  overflow-wrap: break-word;
  display: inline-block;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.text-subtitle {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.114px;
  color: #707070;
  width: 100%;
  height: 44px;
  overflow-wrap: break-word;
  display: inline-block;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-line-clamp: min(1, 2);
  -webkit-line-clamp: max(1, 2);
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.box-right {
  width: 100%;
}

.subtitle-text {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 28px;
}

.text-badge {
  font-size: 12px;
  letter-spacing: 0.15px;
}

.theme--light.v-chip.active:not(.v-chip--active) {
  background: #fbe7e7;
}

.theme--light.v-chip.active {
  border-color: rgb(194 38 38 / 12%);
  color: #da3d3c;
}

.theme--light.v-chip.failed:not(.v-chip--active) {
  background: #eaeaea;
}

.theme--light.v-chip.failed {
  border-color: rgb(194 38 38 / 12%);
  color: #707070;
}

@media only screen and (max-width: 360px) {
  .subtitle-text {
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 320px) {
  .subtitle-text {
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 28px;
  }
}
</style>
