<template>
  <div class="count-down">
    <div class="count-down-grid">
      <span>{{ day }}</span>
      <span class="count-down-grid-unit">วัน</span>
    </div>
    <div class="count-down-grid">
      <span>{{ hour }}</span>
      <span class="count-down-grid-unit">ชั่วโมง</span>
    </div>
    <div class="count-down-grid">
      <span>{{ min }}</span>
      <span class="count-down-grid-unit">นาที</span>
    </div>
    <div class="count-down-grid">
      <span>{{ second }}</span>
      <span class="count-down-grid-unit">วินาที</span>
    </div>
  </div>
</template>

<script>
import * as dayjs from 'dayjs'

export default {
  name: 'BaseCountdown',
  props: {
    startDate: {
      type: [String, Date, Number],
      required: false,
    },
    endDate: {
      type: [String, Date, Number],
      require: true,
    },
  },
  data() {
    return {
      diff: 0,
      day: 0,
      hour: 0,
      min: 0,
      second: 0,
      intervalKey: null,
    }
  },
  created() {
    this.setCountdown()
  },
  methods: {
    setCountdown() {
      // Interval 1s
      this.intervalKey = setInterval(() => {
        const start = dayjs(this.startDate)
        const end = dayjs(this.endDate)

        this.day = end.diff(start, 'day') % 365
        this.hour = end.diff(start, 'hour') % 24
        this.min = end.diff(start, 'minute') % 60
        this.second = end.diff(start, 'second') % 60
      }, 1000)
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalKey)
  },
}
</script>

<style lang="scss" scoped>
.count-down {
  color: white;
  background-color: #383838;
  border-radius: 28px;

  font-size: 22px;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 320px;
  height: 56px;

  &-grid {
    display: flex;
    flex-flow: column;
    flex: 1 1 0;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    line-height: 0.5cm;
    margin-top: 2px;

    &-unit {
      font-size: 11px;
      font-weight: normal;
      color: #adadad;
      text-align: center;
    }

    &:not(:last-child) {
      border-right: 2px solid #e3e3e3;
    }

    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      padding-right: 15px;
    }
  }
}
</style>
