export const VISIBLE_VALUES = {
  ALL: 'all',
  JP_AND_ABOVE: 'isVisibleJpAndAbove',
  EP_ONLY: 'isVisibleEp',
}

export const VISIBLE_LABELS = {
  ALL: 'ทั้งหมด',
  JP_AND_ABOVE: 'สำหรับ Pacer',
  EP_ONLY: 'สำหรับ EP เท่านั้น',
}

export const VISIBLE_ALERT = {
  ALL: 'ทั้งหมด',
  JP_AND_ABOVE: 'เนื้อหานี้สำหรับ Pacer เท่านั้น',
  EP_ONLY: 'เนื้อหานี้สำหรับ EP เท่านั้น',
}

export default [
  {
    label: VISIBLE_LABELS.ALL,
    value: VISIBLE_VALUES.ALL,
    alert: VISIBLE_ALERT.ALL,
  },
  {
    label: VISIBLE_LABELS.JP_AND_ABOVE,
    value: VISIBLE_VALUES.JP_AND_ABOVE,
    alert: VISIBLE_ALERT.JP_AND_ABOVE,
  },
  {
    label: VISIBLE_LABELS.EP_ONLY,
    value: VISIBLE_VALUES.EP_ONLY,
    alert: VISIBLE_ALERT.EP_ONLY,
  },
]
