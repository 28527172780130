import { apiServices } from '@/utils/request'

export default {
  AuthLogin({ username, password }) {
    return apiServices.post('/admin/signin', { username, password })
  },
  CheckToken() {
    return apiServices.get('/admin/checkToken')
  },
}
