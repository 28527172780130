<template>
  <div id="Gallery" v-if="images.length > 0">
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="lb-modal" v-if="index >= 0">
        <button
          class="btn btn-outline-danger btn-sm lb-modal-close"
          @click="close"
        >
          <i class="fas far fa-times"></i>
        </button>
        <button class="btn btn-outline-primary lb-modal-prev" @click="next">
          <i class="fas far fa-angle-left fa-2x"></i>
        </button>

        <button class="btn btn-outline-primary lb-modal-next" @click="prev">
          <i class="fas far fa-angle-right fa-2x"></i>
        </button>
        <div class="lb-modal-img" @click="close">
          <img id="imageModal" :src="src" alt="saveImage" />
        </div>

        <v-btn
          color="primary"
          class="ma-6 lb-modal-download"
          fab
          large
          dark
          @click="download()"
        >
          <v-icon v-if="!loadingDowload">mdi-cloud-download</v-icon>
          <v-progress-circular
            v-else
            v-show="loadingDowload"
            indeterminate
            :width="3"
            :size="20"
            color="white"
          ></v-progress-circular>
        </v-btn>
      </div>
    </transition>
    <v-row>
      <v-col
        :cols="i === 0 || i === 1 ? 6 : 4"
        :key="i"
        v-for="(item, i) in images"
      >
        <v-hover v-slot="{ hover }" close-delay="200">
          <v-card
            class="mx-auto"
            :elevation="hover ? 24 : 2"
            :class="{ 'on-hover': hover }"
          >
            <v-img
              @click.prevent="show(i)"
              :src="path + item.image_id"
              aspect-ratio="1"
              :max-height="i === 0 || i === 1 ? 600 : 500"
              class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GalleryServices from '@/services/GalleryServices'

export default {
  name: 'Gallery',
  props: {
    cells: {
      type: Number,
      default: () => 5,
    },
  },
  data() {
    return {
      drawer: null,
      images: [],
      selected: null,
      loadingDowload: false,
      src: '',
      index: -1,
      loading: false,
      events: [],
      transparent: 'rgba(255, 255, 255, 0)',
    }
  },
  computed: {
    path() {
      return this.$appConfig.serveConfig.SERVICE_URL_IMAGE
    },
  },
  async mounted() {
    await GalleryServices.getGallery()
      .then(resp => {
        this.images = resp.data
      })
      .catch(err => {
        console.error(err)
      })
  },
  methods: {
    select(path) {
      this.drawer = !this.drawer
      this.selected = path
    },
    download() {
      this.loadingDowload = !this.loadingDowload

      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        // true for mobile device
        var img = new Image()
        img.src = this.src
        img.crossOrigin = 'Anonymous'
        img.onload = () => {
          const content = this.imageToBase64(img)
          try {
            this.sendMessage('image', content)
            this.loadingDowload = !this.loadingDowload
          } catch (error) {
            alert(error.message)
            this.loadingDowload = !this.loadingDowload
          }
        }
      } else {
        // false for not mobile device
        GalleryServices.downloadImage(this.src)
          .then(response => {
            this.loadingDowload = !this.loadingDowload
            var fileURL = window.URL.createObjectURL(new Blob([response.data]))
            var fileLink = document.createElement('a')

            fileLink.href = fileURL
            const random =
              Math.random()
                .toString(36)
                .substring(2, 15) +
              Math.random()
                .toString(36)
                .substring(2, 15)
            fileLink.setAttribute('download', `moomall${random}.png`)
            document.body.appendChild(fileLink)

            fileLink.click()
          })
          .catch(() => {
            this.loadingDowload = !this.loadingDowload
          })
      }
    },
    imageToBase64(img) {
      var canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      var ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, img.width, img.height)
      var dataURL = canvas.toDataURL('image/png')
      return dataURL
    },
    sendMessage(type, content) {
      // eslint-disable-next-line no-undef
      const postMessage =
        !!uni && !!uni.postMessage ? uni.postMessage : window.postMessage
      postMessage({
        data: {
          type: type,
          content: content,
        },
      })
    },
    bind() {
      if (this.events.length > 0) return
      this.events.push([
        'keydown',
        e => {
          if (this.index < 0) return
          if (e.keyCode === 37) {
            this.prev()
          } else if (e.keyCode === 39) {
            this.next()
          } else if (e.keyCode === 27) {
            this.close()
          }
        },
      ])
      this.events.forEach(e => {
        window.addEventListener(e[0], e[1])
      })
    },
    show(i) {
      this.drawer = !this.drawer
      if (i >= this.images.length) {
        i = 0
      }
      if (i < 0) {
        i = this.images.length - 1
      }

      this.loadingDowload = true
      this.bind()
      this.index = i
      var src = this.images[i]
      this.loadingDowload = false
      this.src = this.path + src.image_id
      // var img = new Image();
      // img.src = src;
      // img.onload = () => {
      //   this.loading = false;
      //   this.src = src;
      // }
    },
    next() {
      this.show(this.index - 1)
    },
    prev() {
      this.show(this.index + 1)
    },
    close() {
      this.index = -1
      this.events.forEach(e => {
        window.removeEventListener(e[0], e[1])
      })
      this.events = []
    },
    bg(i) {
      return i && i.length > 0 ? `background-image: url('${i}')` : ''
    },
  },
}
</script>

<style scoped>
.lb-more {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  color: #fff;
  font-size: 3rem;
  background-color: rgba(0, 0, 0, 0.4);
}

.lb-more:before {
  display: inline-block;
  content: '';
  vertical-align: middle;
  height: 100%;
}

.lb-modal {
  transition: opacity 0.2s ease;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: block;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.lb-modal-close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #fff;
}

.lb-modal-download {
  position: fixed;
  right: 0px;
  bottom: 0px;
  color: #fff;
}

.lb-modal-next,
.lb-modal-prev {
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -25px;
  z-index: 999;
  cursor: pointer;
  color: #fff;
  background-color: transparent;
  border: none;
}

.lb-modal-next {
  left: auto;
  right: 20px;
}

.lb-modal-img {
  position: absolute;
  top: 10px;
  left: 70px;
  right: 70px;
  bottom: 10px;
  text-align: center;
}

.lb-modal-img:before {
  display: inline-block;
  content: '';
  vertical-align: middle;
  height: 100%;
}

.lb-modal-img img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  object-fit: contain;
}

.lb-modal-img .spinner {
  width: 150px;
  position: absolute;
  margin-left: -75px;
  top: 50%;
  left: 50%;
  z-index: 1;
}
</style>
