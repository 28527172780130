<template>
  <div class="text-center">
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
        color="red"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'BaseLoading',
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>

<style scoped></style>
