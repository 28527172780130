<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style scoped>
@font-face {
  font-family: 'Prompt';
  src: local('Prompt'),
    url(./assets/fonts/Prompt-Regular.ttf) format('truetype');
}

/* Light */
@font-face {
  font-family: Helvetica;
  src: url(./assets/fonts/Helvetica-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
}

/* Normal */
@font-face {
  font-family: Helvetica;
  src: url(./assets/fonts/Helvetica.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
}

/* Bold */
@font-face {
  font-family: Helvetica;
  src: url(./assets/fonts/Helvetica-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: bold;
}

/* Thin */
@font-face {
  font-family: Helvetica Neue;
  src: url(./assets/fonts/HelveticaNeueThin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* Light */
@font-face {
  font-family: Helvetica Neue;
  src: url(./assets/fonts/HelveticaNeueLt.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Normal */
@font-face {
  font-family: Helvetica Neue;
  src: url(./assets/fonts/HelveticaNeue.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
}

/* Italic */
@font-face {
  font-family: Helvetica Neue;
  src: url(./assets/fonts/HelveticaNeueIt.ttf) format('truetype');
  font-style: italic;
}

/* Medium */
@font-face {
  font-family: Helvetica Neue;
  src: url(./assets/fonts/HelveticaNeueMed.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Bold: 700 */
@font-face {
  font-family: Helvetica Neue;
  src: url('./assets/fonts/HelveticaNeueBd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Heavy */
@font-face {
  font-family: 'Helvetica Neue';
  src: url(./assets/fonts/HelveticaNeueHv.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

#app {
  font-family: Helvetica, Roboto, Droid Sans, Arial, sans-serif !important;
  background-color: #f8f6f6;
}

:lang(en) {
  font-weight: 400;
}

:lang(th) {
  font-weight: 300;
}
</style>
