<template>
  <div>
    <v-file-input
      v-model="files"
      color="deep-purple accent-4"
      counter
      accept="image/png, image/jpeg, image/bmp"
      label="File input image"
      @change="uploadImages"
      placeholder="Select your files"
      prepend-icon="mdi-camera"
      required
      :show-size="1000"
      :rules="[v => !!v || 'Required']"
    >
      <template v-slot:selection="{ index, text }">
        <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small>
          {{ text }}
        </v-chip>

        <span
          v-else-if="index === 2"
          class="overline grey--text text--darken-3 mx-2"
        >
          +{{ files.length - 2 }} File(s)
        </span>
      </template>
    </v-file-input>
  </div>
</template>

<script>
import { FormMixin } from '@/Mixins/FormMixin'

export default {
  mixins: [FormMixin],
  name: 'BaseFileImage',
}
</script>

<style scoped></style>
