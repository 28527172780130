<template>
  <v-container class="py-0" v-if="images.length > 0">
    <v-row dense>
      <v-col cols="12">
        <v-row dense class="white--text">
          <v-col>
            <router-link to="/gallery" class="text-decoration-none white--text">
              <div class="float-left">
                {{ $t('navBarMoomall.gallery') }}
              </div>
            </router-link>
          </v-col>
          <v-col>
            <router-link to="/gallery" class="text-decoration-none white--text">
              <div class="float-right">
                {{ $t('mooMallNews.seeMore') }}
              </div>
            </router-link>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col
            cols="3"
            v-for="(item, i) in images.slice(0, 4)"
            :key="i"
            align="center"
            justify="center"
          >
            <v-img
              @click="redirectTo()"
              :src="path + item.image_id"
              aspect-ratio="1"
              max-width="81"
              class="grey lighten-2 rounded-lg"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GalleryServices from '@/services/GalleryServices'

export default {
  name: 'BaseCardGallery',
  data() {
    return {
      images: [],
    }
  },
  async mounted() {
    await GalleryServices.getGallery()
      .then(resp => {
        this.images = resp.data
      })
      .catch(err => {
        console.error(err)
      })
  },
  methods: {
    redirectTo() {
      this.$router.push('/gallery')
    },
  },
  computed: {
    path() {
      return this.$appConfig.serveConfig.SERVICE_URL_IMAGE
    },
  },
}
</script>

<style scoped></style>
