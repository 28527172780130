<template>
  <v-app-bar app color="#F8F6F6" flat height="60">
    <v-btn icon @click="toBack" v-show="!isRedirectBack" id="back-icon">
      <BaseIcon nameIcon="icon-back-new" height="15" cssIcon="red--text" />
    </v-btn>
    <div class="title-text title" v-if="!isShowSearchInput">
      <slot></slot>
    </div>
    <v-col cols="10" v-if="isShowSearchInput">
      <slot name="searchInput"></slot>
    </v-col>
    <v-spacer></v-spacer>
    <div
      class=" title-text mr-2"
      v-show="isShare"
      @click="shareViaWebShare"
      v-if="webShareApiSupported"
    >
      <BaseIcon nameIcon="icon-share-nav-new2" />
    </div>
    <v-btn
      icon
      class=" bg-search  ma-0"
      v-show="isSearch"
      small
      @click="Search"
    >
      <v-icon color="#da3d3c" small>mdi-magnify</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import * as uni from '@/assets/js/uni.webview.1.5.2.js'

export default {
  name: 'NavbarForMain',
  props: {
    isSearch: {
      type: Boolean,
      default: false,
    },
    isRedirectBack: {
      type: Boolean,
      default: false,
    },
    isShare: {
      type: Boolean,
      default: false,
    },
    isShowSearchInput: {
      type: Boolean,
      default: false,
    },
    isVisibleEp: {
      type: Boolean,
      default: false,
    },
    isVisibleJp: {
      type: Boolean,
      default: false,
    },
    isVisibleSp: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    webShareApiSupported() {
      return navigator.share
    },
  },
  methods: {
    Search() {
      this.$router.push({ name: 'Search' })
    },
    toBack() {
      const { isuni } = this.$route.query

      // should use uni back on create, report (root) page
      // because it is first page when open from uni-app (Moomall-app)
      if (isuni === 'true') {
        uni.navigateBack()
      } else {
        this.$router.go(-1)
      }
    },
    shareViaWebShare() {
      if (this.isJpAndAbove() || this.isEpOnly()) {
        return this.popupForbiddenItem('error')
      }
      if (navigator.share) {
        navigator
          .share({
            title: 'moomall Guide',
            text: 'moomall Guide',
            url: window.location.href,
          })
          .then(() => console.log('Successful share'))
          .catch(error => console.log('Error sharing', error))
      } else {
        this.$swal.fire({
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: 'Version not support.',
          toast: true,
          timerProgressBar: true,
        })
      }
    },
    isJpAndAbove() {
      return this.isVisibleEp && this.isVisibleSp && this.isVisibleJp
    },
    isEpOnly() {
      return this.isVisibleEp && !this.isVisibleSp && !this.isVisibleJp
    },
    popupForbiddenItem(icon) {
      this.$swal.fire({
        icon: icon,
        text:
          'ไม่สามารถแชร์ได้ เนื่องจากเนื้อหานี้มีการจำกัดสิทธิ์ในการเข้าถึง',
      })
    },
  },
}
</script>

<style scoped>
#back-icon {
  padding-right: 15px;
}
.bg-search {
  background-color: #f8f6f6;
}

.title-text {
  color: #da3d3c;
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px !important;
  font-weight: normal !important;
}
</style>
