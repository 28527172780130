<template>
  <v-simple-table class="base-month-score-table">
    <template v-slot:default>
      <thead>
        <tr>
          <th>
            {{ $t('month.title') }}
          </th>
          <th>
            {{ $t('side.left') }}
          </th>
          <th>
            {{ $t('side.right') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ fullMonth(item.month) }}</td>
          <td>{{ format(item[itemLeft]) }}</td>
          <td>{{ format(item[itemRight]) }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import * as dayjs from 'dayjs'
import * as numeral from 'numeral'
import { getFullMonthTH } from '@/constants/monthsTH.js'

export default {
  name: 'BaseMonthScoreTable',
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    itemLeft: {
      type: String,
      default: 'L',
    },
    itemRight: {
      type: String,
      default: 'R',
    },
    numberFormat: {
      type: String,
      default: '0,0.0',
    },
  },
  methods: {
    fullMonth(month) {
      return this.$i18n.locale.includes('en')
        ? dayjs()
            .set('month', month - 1)
            .format('MMMM')
        : getFullMonthTH(month)
    },
    format(value) {
      return numeral(value).format(this.numberFormat)
    },
  },
}
</script>

<style lang="scss" scoped>
.base-month-score-table {
  thead {
    tr {
      th {
        padding: 0 !important;
        border: none !important;
        font-size: 10px !important;
        color: #c3c8d5 !important;
        font-weight: bold !important;
        height: auto !important;
        text-align: right !important;

        &:first-child {
          text-align: left !important;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0 !important;
        border-bottom: thin solid #e2e8f2 !important;
        font-size: 14px !important;
        height: 2rem !important;
        text-align: right !important;
        font-weight: bold !important;

        &:first-child {
          text-align: left !important;
          font-weight: normal !important;
        }

        &:not(:first-child) {
          width: 100px !important;
        }
      }
    }
  }
}
</style>
