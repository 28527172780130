<template>
  <div class="base-score-progress">
    <div class="base-score-progress-content">
      <div class="base-score-progress-content-label">
        <BaseIcon :isSvg="true" nameIcon="right-side.svg" />
        <span class="base-score-progress-content-label-text">{{
          textLeft
        }}</span>
      </div>
      <div>
        <span class="base-score-progress-content-value-current">{{
          format(valueLeft)
        }}</span>
        <span class="base-score-progress-content-value-max">
          /{{ format(maxValueLeft, '0,0') }}</span
        >
      </div>
    </div>
    <BaseProgressLinear
      :value="(valueLeft / maxValueLeft) * 100"
      :cssProgress="{ marginTop: '2px' }"
    />

    <div class="base-score-progress-content mt-4">
      <div class="base-score-progress-content-label">
        <BaseIcon :isSvg="true" nameIcon="left-side.svg" />
        <span class="base-score-progress-content-label-text">{{
          textRight
        }}</span>
      </div>
      <div class="base-score-progress-content-value">
        <span class="base-score-progress-content-value-current">{{
          format(valueRight)
        }}</span>
        <span class="font-weight-light" style="font-size: 16px">
          /{{ format(maxValueRight, '0,0') }}</span
        >
      </div>
    </div>
    <BaseProgressLinear
      :value="(valueRight / maxValueRight) * 100"
      :cssProgress="{ marginTop: '2px' }"
    />
  </div>
</template>

<script>
import * as numeral from 'numeral'

export default {
  name: 'BaseScoreProgress',
  props: {
    textLeft: {
      type: String,
      default: 'คะแนนสะสมทีมซ้าย',
    },
    textRight: {
      type: String,
      default: 'คะแนนสะสมทีมขวา',
    },
    maxValueLeft: {
      type: Number,
      default: 0,
    },
    maxValueRight: {
      type: Number,
      default: 0,
    },
    valueLeft: {
      type: [Number, String],
      default: 0,
    },
    valueRight: {
      type: [Number, String],
      default: 0,
    },
  },
  methods: {
    format(value, format) {
      return format
        ? numeral(value).format(format)
        : numeral(value).format('0,0.0')
    },
  },
}
</script>

<style lang="scss" scoped>
.base-score-progress {
  &-content {
    display: flex;
    justify-content: space-between;

    &-label {
      display: flex;
      align-items: center;

      &-text {
        margin-left: 4px;
        font-size: 14.5px;
      }
    }

    &-value {
      font-size: 1rem;

      &-current {
        font-weight: bold;
        color: #db1f26;
      }

      &-max {
        // Light
        font-weight: 300;
      }
    }
  }
}
</style>
