export const PACER_NAME = {
  0: 'MEMBER',
  1: 'JP',
  2: 'SP',
  3: 'EP',
}

export const PACER_FULL_NAME = {
  0: 'MEMBER',
  1: 'Junior Pacer (JP)',
  2: 'Senior Pacer (SP)',
  3: 'Executive Pacer (EP)',
}

export const PACER_ICON = {
  0: 'icon-member',
  1: 'icon-JP',
  2: 'icon-SP',
  3: 'icon-EP',
}

export default {
  MEMBER: 0,
  JP: 1,
  SP: 2,
  EP: 3,
}
