<template>
  <div :class="classDropdown" :style="styleDropdown">
    <v-select
      v-model="select"
      :label="label"
      :items="filterItems"
      :item-text="itemText"
      :item-value="itemValue"
      :color="color"
      @input="onInput"
      dense
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'BaseEvent',
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#d11515',
    },
    classDropdown: {
      type: String,
      default: '',
    },
    styleDropdown: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      select: null,
    }
  },
  computed: {
    filterItems() {
      return this.items.filter(item => item.active)
    },
  },
  methods: {
    onInput() {
      this.$emit('input', this.select)
    },
  },
}
</script>

<style lang="scss" scoped></style>
