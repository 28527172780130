<template>
  <v-progress-linear
    rounded
    :style="cssProgress"
    :color="color"
    :background-color="backgroundColor"
    :value="value"
    :height="height"
  ></v-progress-linear>
</template>

<script>
export default {
  name: 'BaseProgressLinear',
  props: {
    color: {
      type: String,
      default: '#DB1F26',
    },
    backgroundColor: {
      type: String,
      default: '#E9E9EB',
    },
    value: {
      type: [String, Number],
      default: '0',
    },
    height: {
      type: [String, Number],
      default: '8px',
    },
    cssProgress: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>
