<template>
  <div v-if="text">
    <div :class="css" v-html="text"></div>
  </div>
</template>

<script>
export default {
  name: 'TextDetail',
  props: {
    text: String,
    css: String,
  },
}
</script>

<style>
.text-indent {
  text-indent: 20px;
}

.body-text {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.red-box {
  color: red;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e9b4b4;
  background-color: #ffefef;
  margin-bottom: 15px;
}

.list-item {
  text-indent: 0px !important;
  margin-bottom: 15px;
}
</style>
