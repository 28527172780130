<template>
  <div style="padding:56.25% 0 0 0;position:relative;">
    <iframe
      :src="checkPart(pathVideo)"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position:absolute;top:0;left:0;width:100%;height:100%;"
      title="momall academy"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'BaseVimeo',

  props: {
    pathVideo: {
      type: [Number, String],
      default: '',
    },
  },
  methods: {
    checkPart(link) {
      const checkLinkManage = link.split('https://vimeo.com/manage/videos/')
      if (checkLinkManage.length > 1) {
        return (
          'https://player.vimeo.com/video/' +
          checkLinkManage[1] +
          '?autoplay=1&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
        )
      }

      const checkLink = link.split('https://vimeo.com/')
      if (checkLink.length > 1) {
        const linkSub = checkLink[1]
        const resultSub = linkSub.split('/')
        if (resultSub.length > 1) {
          return (
            'https://player.vimeo.com/video/' +
            resultSub[0] +
            '?h=' +
            resultSub[1] +
            '&autoplay=1&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          )
        } else {
          return (
            'https://player.vimeo.com/video/' +
            linkSub +
            '?autoplay=1&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          )
        }
      }
    },
  },
}
</script>
