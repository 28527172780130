import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { config } from '@/config'

Vue.use(VueI18n)
const messages = {
  en_EN: {
    navBarMoomall: {
      moomallGuide: 'Academy',
      moomallNewsDetail: 'moomall Update',
      information: 'moomall Information',
      manual: 'User Manual',
      shop: 'Open shop',
      contact: 'Contact moomall',
      gallery: 'Gallery',
    },
    mooMallNews: {
      mooMallUpdate: 'moomall Update',
      seeMore: 'See more >>',
      readMore: 'Read more >>',
      share: 'Share',
      new: 'New',
    },
    contact: {
      pacer: 'For Pacer',
      fbFanPage: 'Facebook Fan Page',
      lineGroup: 'Line Group',
      fbGroup: 'Facebook Group',
      fbGroupDetail:
        'Follow the announcement And activities from the moomall team',
      moomallAcademy: 'moomall Academy',
      mootrainingDetails: 'Good techniques from the moomall team',
      instagram: 'Instagram',
      youtube: 'Youtube',
      twitter: 'Twitter',
      shop: 'For Shop',
      shopDetail: 'Sell items on moomall',
      openShop: 'Open shop on moomall',
      contactMoomall: 'Contact our customer service moomall',
      contactDetail: 'Business hours Monday-Sunday 9:00 a.m. - 8:00 p.m.',
      contactPhone: 'Phone Number: 02-114-6595',
    },
    search: {
      title: 'Search',
      historySearch: 'Search History',
      searchNotFound: 'Not result...',
    },
    personalScore: {
      title: 'Personal Score/month',
    },
    teamScore: {
      title: 'Team Score',
      left: 'Team Score Left',
      right: 'Team Score Right',
    },
    g1Score: {
      title: 'G1 Score',
      left: 'G1 Score Left',
      right: 'G1 Score Right',
    },
    side: {
      left: 'Left',
      right: 'Right',
    },
    month: {
      title: 'Month',
    },
    activeCourse: {
      afterActive: 'Finish',
      Active: 'Active',
    },
  },
  th_TH: {
    navBarMoomall: {
      moomallGuide: 'Academy',
      moomallNewsDetail: 'moomall Update',
      information: 'moomall คืออะไร',
      manual: 'คู่มือการ ใช้งานแอปฯ',
      shop: 'เปิดร้าน บนมูมอล',
      contact: 'ช่องทางติดต่อ moomall',
      gallery: 'อัลบั้มรูปภาพ',
    },
    mooMallNews: {
      mooMallUpdate: 'มูมอล อัปเดต',
      seeMore: 'แสดงเพิ่มเติม >>',
      readMore: 'อ่านเพิ่มเติม >>',
      share: 'แชร์',
      new: 'ใหม่',
    },
    contact: {
      pacer: 'สำหรับ Pacer',
      fbFanPage: 'เฟซบุ๊กแฟนเพจ',
      lineGroup: 'ไลน์ออฟฟิเชียล moomall',
      fbGroup: 'เฟซบุ๊กกลุ่ม moomall',
      fbGroupDetail: 'ติดตามข่าวประกาศ และกิจกรรมจากทีมงาน moomall',
      moomallAcademy: 'เรียนกับ moomall',
      mootrainingDetails: 'กลุ่มปิดเพื่อการเรียนรู้เฉพาะ pacer เท่านั้น',
      instagram: 'อินสตาแกรม',
      youtube: 'Youtube',
      twitter: 'Twitter',
      shop: 'สำหรับร้านค้า',
      shopDetail: 'คู่ค้าด้านผลิตภัณฑ์',
      openShop: 'คู่ค้าด้านบริการ',
      contactMoomall: 'ติดต่อฝ่ายลูกค้าสัมพันธ์ของ moomall',
      contactDetail: 'เวลาทำการ จันทร์-อาทิตย์ 9.00-20.00 น.',
      contactPhone: 'เบอร์โทรศัพท์: 02-114-6595',
    },
    search: {
      title: 'ค้นหา',
      historySearch: 'ประวัติการค้นหา',
      searchNotFound: 'ไม่พบเนื้อหาที่ค้นหา...',
    },
    personalScore: {
      title: 'คะแนนส่วนตัว/เดือน',
    },
    teamScore: {
      title: 'คะแนนสะสมทีม',
      left: 'คะแนนสะสมทีมซ้าย',
      right: 'คะแนนสะสมทีมขวา',
    },
    g1Score: {
      title: 'คะแนน G1',
      left: 'คะแนน G1 ซ้าย',
      right: 'คะแนน G1 ขวา',
    },
    side: {
      left: 'ซ้าย',
      right: 'ขวา',
    },
    month: {
      title: 'เดือน',
    },
    activeCourse: {
      afterActive: 'เรียนแล้ว',
      Active: 'กำลังเรียน',
    },
  },
}

const i18n = new VueI18n({
  locale: config.defaultLanguage,
  messages,
})
export default i18n
