<template>
  <div v-if="pathVideo">
    <iframe :class="cssVideo" :src="path" frameborder="0" allowfullscreen>
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'BaseVideo',
  props: {
    pathVideo: {
      type: [Number, String],
      default: '',
    },
    cssVideo: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    path() {
      return `${this.pathVideo}`
    },
  },
}
</script>

<style scoped>
.video {
  width: 100%;
  height: 288px;
}
</style>
