import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { NewsStore } from './Main/NewsStore/NewsStore'
import { MoomallContentStore } from './Main/MoomallContentStore/MoomallContentStore'
import { SearchHistoryStore } from '@/store/Main/SearchHistoryStore/SearchHistoryStore'
import { AuthStore } from '@/store/Admin/AuthStore/AuthStore'
import { PacerStore } from './Main/PacerStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    NewsStore,
    MoomallContentStore,
    SearchHistoryStore,
    AuthStore,
    PacerStore,
  },
  plugins: [
    createPersistedState(
      {
        key: 'moomall-guide',
        paths: [
          'NewsStore',
          'MoomallContentStore',
          'SearchHistoryStore',
          'AuthStore',
        ],
      },
      { storage: window.sessionStorage },
    ),
  ],
})
