import { apiServices, apiDownload } from '@/utils/request'

export default {
  getGallery() {
    return apiServices.get('/gallery')
  },

  downloadImage(urlImage) {
    return apiDownload.get(urlImage)
  },

  createGallery(formData) {
    return apiServices.post('gallery/multiple', formData)
  },

  updateGallery(id, formData) {
    return apiServices.put(`/gallery/${id}`, formData)
  },

  deleteGallery(id) {
    return apiServices.delete(`/gallery/${id}`)
  },
}
