<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog">
      <v-card class="text-center rounded-xl">
        <v-card-text class="pa-5 pb-0">
          <BaseIcon
            class="pr-4"
            nameIcon="icon-alert"
            width="25"
            height="25"
            cssIcon="mx-auto mb-2"
          />
        </v-card-text>
        <p class="text-center pa-5 pt-2">
          {{ label }}
        </p>
        <v-text-field
          class="px-3 py-0"
          v-model="mobile"
          :label="label"
          clearable
        ></v-text-field>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-spacer>
            <v-btn color="red" block text @click="closeDialog()">
              ตกลง
            </v-btn>
          </v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BaseIcon from '@/components/Main/BaseIcon'
export default {
  name: 'BaseDialog',
  components: { BaseIcon },
  emits: ['activeDialog', 'update:mobile'],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      mobile: '',
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.$emit('activeDialog', false)
      this.$emit('mobile', this.mobile)
    },
  },
}
</script>
