<template>
  <v-card flat tile v-if="numberCourse > 1">
    <v-card-title v-if="!sellerSku">
      <h4>เนื้อหาในคอร์สนี้ ({{ numberCourse }})</h4>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-title v-else>
      <h4>เนื้อหาในคอร์สนี้ ({{ numberCourse }})</h4>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!isContant && !isContantSub"
        @click="dialog = true"
        class="enable"
        rounded
        color="#fbe7e7"
        elevation="0"
      >
        ไม่ได้ซื้อคอร์สนี้
      </v-btn>
      <v-btn
        v-else
        class="disable"
        disabled
        rounded
        color="#EAEAEA"
        elevation="0"
      >
        ซื้อแล้ว
      </v-btn>
    </v-card-title>
    <BaseDialogCheckMoblie
      @activeDialog="activeDialog"
      @mobile="passMobile"
      :dialog="dialog"
      label="กรอกเบอร์โทร"
    />
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import BaseDialogCheckMoblie from '@/components/Main/BaseDialogCheckMoblie'
export default {
  name: 'BaseLabelCardList',
  emits: ['update:activeContantMobile'],
  components: { BaseDialogCheckMoblie },
  props: {
    label: {
      type: [Number, String],
      default: '',
    },
    numberCourse: {
      type: [Number, String],
      default: 0,
    },
    isContant: {
      type: Boolean,
      required: true,
    },
    isContantSub: {
      type: Boolean,
      required: true,
    },
    sellerSku: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    activeDialog() {
      this.dialog = false
    },
    passMobile(value) {
      this.setUserMobile({ userMobile: value })
      this.$emit('activeContantMobile', value)
    },
    ...mapMutations({
      setUserMobile: 'PacerStore/setUserMobile',
    }),
  },
}
</script>

<style scoped>
.theme--light.v-card {
  background-color: transparent;
}
.theme--light.v-btn.enable {
  color: #da3d3c;
}
.theme--light.v-btn.disable {
  color: #707070;
}
</style>
