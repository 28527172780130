<template>
  <div>
    <div class="profile-image">
      <v-img
        class="profile-image-head"
        :src="headImgPath"
        alt="profile"
        width="60px"
        height="60px"
      >
      </v-img>
      <v-img
        class="profile-image-level"
        :src="level"
        alt="JP"
        width="24"
        height="24"
      />
    </div>
    <span class="profile-name">{{ name }}</span>
  </div>
</template>

<script>
export default {
  name: 'BaseProfile',
  props: {
    headImg: {
      type: String,
      default: '',
    },
    pacerLevel: {
      type: String,
      defualt: 'JP',
    },
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    level() {
      return require(`@/assets/${this.pacerLevel}.png`)
    },
    headImgPath() {
      return this.headImg
        ? this.headImg
        : require('@/assets/default-profile.svg')
    },
  },
}
</script>

<style lang="scss" scoped>
.profile {
  &-image {
    padding: 1.5rem 0 0 0;
    position: relative;

    &-head {
      border-radius: 50%;
    }

    &-level {
      position: absolute;
      bottom: 0;
      left: -20%;
    }
  }

  &-name {
    font-size: 18px;
    font-weight: bold;
    padding-top: 0.5rem;
  }
}
</style>
