function getKeyByLang(key, lang) {
  let text
  if (lang === 'th_TH') {
    text = key + 'th'
    console.log(text)
  }
  return text
}

export default {
  getKeyByLang,
}
