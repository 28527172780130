<template>
  <nav>
    <v-navigation-drawer app v-model="drawer">
      <v-list shaped>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-btn icon fab>
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-subtitle>Admin</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider light></v-divider>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            link
            router
            :to="{ name: child.router }"
          >
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app hide-on-scroll>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <div class="subtitle-1 ma-2">{{ this.$appConfig.version }}</div>
      <v-menu offset-y v-if="token">
        <template v-slot:activator="{ on }">
          <v-btn class="hidden-sm-and-down" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(menu, index) in Menus"
            :key="index"
            @click="logout(index)"
            :to="menu.router"
          >
            <v-list-item-icon>
              <v-icon right>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </nav>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import EventServices from '@/services/EventServices'

export default {
  name: 'Navbar',
  data() {
    return {
      drawer: null,
      dialogLogin: { dialog: false },
      Menus: [{ title: 'Logout', icon: 'mdi-logout-variant', router: '' }],
      items: [
        {
          id: 1,
          title: 'News',
          action: 'mdi-star',
          router: 'NewsAdmin',
          items: [
            {
              id: 1,
              title: 'Manage News',
              action: 'mdi-star-outline',
              router: 'NewsAdmin',
            },
          ],
        },
        {
          id: 2,
          title: 'Footers',
          action: 'mdi-star',
          router: 'FootersAdmin',
          items: [
            {
              id: 1,
              title: 'Manage Footers',
              action: 'mdi-star-outline',
              router: 'FootersAdmin',
            },
          ],
        },
        {
          id: 3,
          title: 'Content',
          action: 'mdi-star',
          items: [
            {
              id: 1,
              title: 'Manage Content',
              action: 'mdi-star-outline',
              router: 'ContentAdmin',
            },
            {
              id: 2,
              title: 'Sub Content List',
              action: 'mdi-star-outline',
              router: 'Contentlist',
            },
          ],
        },
        {
          id: 4,
          title: 'Gallery',
          action: 'mdi-star',
          items: [
            {
              id: 1,
              title: 'Manage Gallery',
              action: 'mdi-star-outline',
              router: 'GalleryAdmin',
            },
          ],
        },
        {
          id: 5,
          title: 'Course',
          action: 'mdi-star',
          items: [
            {
              id: 1,
              title: 'Manage Phone',
              action: 'mdi-star-outline',
              router: 'CoursePhone',
            },
          ],
        },
        {
          id: 6,
          title: 'Events',
          action: 'mdi-star',
          items: [],
        },
      ],
    }
  },
  async created() {
    const res = await EventServices.getEvents()

    // NOTE: Events will always at bottom
    if (res.status === 200) {
      const events = res.data || []
      events.forEach((event, index) => {
        event.active &&
          this.items[this.items.length - 1].items.push({
            id: index + 1,
            title: event.name,
            action: 'mdi-star-outline',
            // NOTE: Because item defined to access with RouteName so we decided to use Dashbaord + EventName to route
            router: `Dashboard${event.name}`,
          })
      })
    }
  },
  methods: {
    ...mapMutations({ removeToken: 'AuthStore/removeToken' }),
    logout(index) {
      console.log(index)
      if (index === 0) {
        this.$swal.fire({
          icon: 'success',
          title: 'Logout successful.',
        })
        this.removeToken()
        location.reload()
      } else {
        return
      }
    },
  },
  computed: {
    ...mapGetters({ token: 'AuthStore/token' }),
  },
}
</script>
