<template>
  <v-btn
    tile
    :text="text"
    :small="small"
    :color="color"
    @click.stop="shareViaWebShare"
  >
    <BaseIcon :nameIcon="icon" :cssIcon="css" />
    <span class="text-capitalize subtitle-text" v-show="isShowText">{{
      $t('mooMallNews.share')
    }}</span>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseButtonShare',
  props: {
    icon: {
      type: [String, Number],
      default: 'icon-share-navbar',
    },
    isShowText: {
      type: Boolean,
      default: false,
    },
    css: {
      type: [String, Number],
      default: '',
    },
    text: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '#626263',
    },
    small: {
      type: Boolean,
      default: false,
    },
    postfix: {
      type: String,
      default: '',
    },
    isExclusive: {
      type: Boolean,
      default: false,
    },
    alertIcon: {
      type: String,
      default: 'error',
    },
    alertMsg: {
      type: String,
      default: '',
    },
  },
  methods: {
    shareViaWebShare() {
      if (this.isExclusive) {
        this.$swal.fire({
          icon: this.alertIcon,
          text: this.alertMsg,
        })
        return
      }
      if (navigator.share) {
        const url = this.postfix
          ? `${window.location.protocol}//${window.location.host}/${this.postfix}`
          : window.location.href
        navigator
          .share({
            title: 'moomall Guide',
            text: 'moomall Guide',
            url: url,
          })
          .then(() => console.log('Successful share'))
          .catch(error => console.log('Error sharing', error))
      }
    },
  },
}
</script>

<style scoped></style>
