<template>
  <div v-if="pathImage">
    <v-img
      :src="path"
      :class="cssImage"
      :height="height"
      :width="width"
      :max-height="height"
      :max-width="width"
    >
      <v-overlay absolute opacity="0" v-show="isVideo !== ''">
        <v-img
          contain
          src="@/assets/icon-play-media.png"
          width="40"
          height="40"
        />
      </v-overlay>
    </v-img>
  </div>
</template>

<script>
export default {
  name: 'BasePdf',
  props: {
    pathImage: {
      type: [Number, String],
      default: '',
    },
    cssImage: {
      type: String,
      default: '',
    },
    isVideo: {
      type: [String, Number],
      default: '',
    },
    width: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    path() {
      return this.$appConfig.serveConfig.SERVICE_URL_IMAGE + this.pathImage
    },
  },
}
</script>

<style scoped></style>
