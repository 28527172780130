<template>
  <div class="d-flex pa-4 pacer-tab">
    <span>Package: </span>
    <span class="pl-3">
      <BaseIcon
        v-if="this.pacerLevelIcon"
        :nameIcon="this.pacerLevelIcon"
        height="30"
        width="30"
    /></span>
    <span style="color:black;" class="pl-3">{{ this.pacerLevel }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PACER_FULL_NAME, PACER_ICON } from '@/constants/pacerLevel'

export default {
  name: 'BasePacer',
  methods: {
    ...mapGetters({ PacerLevel: 'PacerStore/PacerLevel' }),
  },
  computed: {
    pacerLevel() {
      return PACER_FULL_NAME[this.PacerLevel()]
    },
    pacerLevelIcon() {
      return PACER_ICON[this.PacerLevel()]
    },
  },
}
</script>

<style lang="scss" scoped>
.pacer-tab {
  background-color: #fbe7e7;
  color: #707070;
  border-radius: 25px;
}

.v-list-item__subtitle {
  font-size: 1rem;
}
</style>
