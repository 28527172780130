<template>
  <v-btn
    tile
    class="base-btn"
    :class="classBtn"
    :color="color"
    @click="onClick"
  >
    <BaseIcon v-if="icon" :nameIcon="icon" />
    <span v-if="label" :style="styleText">{{ label }}</span>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
    },
    classBtn: {
      type: String,
      default: '',
    },
    styleText: {
      type: Object,
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>

<style scoped>
.base-btn {
  text-transform: none !important;
}
</style>
