import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import utils from './utils/utils'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import ScrollLoader from 'vue-scroll-loader'
import Swal from 'sweetalert2'
import CKEditor from 'ckeditor4-vue'
import configPlugin, { config } from '@/config'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import VueMeta from 'vue-meta'

Vue.use(configPlugin)
Vue.use(CKEditor)
window.Swal = Swal
Vue.use(ScrollLoader)
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
})
const requireComponent = require.context(
  './components/Main',
  false,
  /Base[A-Z]\w+\.(vue|js)$/,
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1')),
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})

const requireComponentAdmin = require.context(
  './components/Admin',
  false,
  /Base[A-Z]\w+\.(vue|js)$/,
)

requireComponentAdmin.keys().forEach(fileName => {
  const componentConfig = requireComponentAdmin(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1')),
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.config.productionTip = false
Vue.component('Navbar', require('./components/Layout/Main/Navbar.vue').default)
Vue.component(
  'NavbarAdmin',
  require('./components/Layout/Admin/Navbar').default,
)
Vue.component('Footer', require('./components/Layout/Main/Footer.vue').default)
Vue.prototype.$utils = utils
Vue.prototype.$swal = Swal

if (config.sentry.dsn) {
  Sentry.init({
    Vue,
    dsn: config.sentry.dsn,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /^\//],
      }),
    ],
    tracesSampleRate: config.sentry.tracesSampleRate,
    logErrors: true,
    environment: config.sentry.env,
  })
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
