export const SearchHistoryStore = {
  namespaced: true,
  actions: {},
  state: {
    SearchHistory: [],
  },
  mutations: {
    getSearchHistory: (state, data) => {
      state.SearchHistory.unshift(data)
    },
    delSearchHistory: state => {
      state.SearchHistory = []
    },
  },
  getters: {
    SearchHistory: state => state.SearchHistory,
  },
}
