import { apiServices } from '@/utils/request'

export default {
  getFooterMenus() {
    return apiServices.get('/footer-menus')
  },
  getFooterMenu(id) {
    return apiServices.get('/footer-menus/' + id)
  },
  createFooter(formData) {
    return apiServices.post('footer-menus', formData)
  },
  deleteFooter(id) {
    return apiServices.delete(`/footer-menus/${id}`)
  },
  updateFooter(id, formData) {
    return apiServices.put(`/footer-menus/${id}`, formData)
  },
}
