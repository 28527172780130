<template>
  <v-footer fixed app height="123" padless v-if="footer_menu.length > 0">
    <v-row no-gutters class="text-center text-footer">
      <v-col cols="3" sm="3" v-for="item in footer_menu" :key="item.id">
        <v-card
          class="mx-auto py-4 px-2 footer_menu"
          tile
          :class="[
            { information: item.sort === 1 },
            { manual: item.sort === 2 },
            { shop: item.sort === 3 },
          ]"
          height="123"
          @click="saveType(item)"
        >
          <BaseIcon
            :nameIcon="item.image_id"
            width="38"
            height="32"
            :isImage="true"
            cssIcon="mx-auto mb-2"
          />
          <div
            class="red--text"
            v-html="$i18n.locale === 'th_TH' ? item.title_th : item.title_en"
          ></div>
        </v-card>
      </v-col>
      <v-col cols="3" sm="3">
        <v-card
          class="mx-auto py-4 px-2 footer_menu"
          tile
          height="123"
          :class="{ contact: contact.id === 3 }"
          @click="saveType(contact)"
        >
          <BaseIcon
            :nameIcon="contact.image_id"
            width="38"
            height="32"
            cssIcon="mx-auto mb-2"
          />
          <div
            class="red--text"
            v-html="
              $i18n.locale === 'th_TH' ? contact.title_th : contact.title_en
            "
          ></div>
        </v-card>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapMutations } from 'vuex'
import FooterMenuServices from '@/services/FooterMenuServices'
import BaseIcon from '@/components/Main/BaseIcon'

export default {
  name: 'FooterForMain',
  components: { BaseIcon },
  data: () => ({
    footer_menu: [],
    contact: {
      id: 3,
      sort: 4,
      image_id: 'icon-share-menu',
      title_th: '<div>ติดต่อ <br/> moomall</div>',
      title_en: '<div>Contact <br/> moomall</div>',
    },
  }),
  methods: {
    ...mapMutations({
      getMoomallContent: 'MoomallContentStore/getMoomallContent',
    }),
    saveType(type) {
      this.getMoomallContent(type)
      this.$router.push(
        type.sort === 4
          ? { name: 'Contact' }
          : {
              name: 'Content',
              params: { id: type.id, isSquare: type.is_square_thumbnail },
            },
      )
    },
  },
  created() {
    FooterMenuServices.getFooterMenus()
      .then(resp => {
        if (resp.status === 200) {
          for (var i = 0; i <= resp.data.length; i++) {
            if (i === 3) {
              break
            }
            this.footer_menu.push(resp.data[i])
          }
        }
      })
      .catch(err => {
        this.$swal
          .fire({
            icon: 'error',
            title: `${err.message}`,
            showDenyButton: false,
          })
          .then(result => {
            if (result.isConfirmed) {
              return
            }
          })
      })
  },
}
</script>

<style>
.footer_menu.information {
  background-color: #fbe7e7;
}

.footer_menu.manual {
  background-color: #ffdbdb;
}

.footer_menu.shop {
  background-color: #fbe7e7;
}

.footer_menu.contact {
  background-color: #ffdbdb;
}

.text-footer {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

@media only screen and (max-width: 360px) {
  .text-footer {
    font-size: 12.5px;
    font-weight: 400;
    line-height: 20px;
  }
}

@media only screen and (max-width: 320px) {
  .text-footer {
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>
