import axios from 'axios'
import { config } from '@/config'

export const apiServices = axios.create({
  baseURL: config.serveConfig.SERVICE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
  mode: 'cors',
})

apiServices.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  },
)

export const apiDownload = axios.create({
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  mode: 'cors',
  responseType: 'blob',
})

apiServices.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  },
)
