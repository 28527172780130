var pjson = require('../package.json')

const config = {
  defaultLanguage: parse(process.env.VUE_APP_DEFAULT_LANGUAGE || 'th_TH'),
  serveConfig: {
    SERVICE_URL: parse(
      process.env.VUE_APP_SERVICE_URL || '*vue-app-service-url*',
    ),
    SERVICE_URL_IMAGE: parse(
      process.env.VUE_APP_SERVICE_URL_IMAGE || '*vue-app-service-url-image*',
    ),
    SERVICE_URL_CH: parse(
      process.env.VUE_APP_SERVICE_URL_CH || '*vue-app-service-url-ch*',
    ),
  },
  sentry: {
    dsn: parse(process.env.VUE_APP_SENTRY_DSN || ''),
    env: parse(process.env.VUE_APP_SENTRY_ENV || 'local'),
    tracesSampleRate: parse(
      process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE || 0.25,
    ),
  },
  features: {},
  version: 'v' + pjson.version,
}

function feature(name) {
  return config.features[name]
}

function parse(value, fallback) {
  if (typeof value === 'undefined') {
    return fallback
  }
  switch (typeof fallback) {
    case 'boolean':
      return !!JSON.parse(value)
    case 'number':
      return JSON.parse(value)
    default:
      return value
  }
}
export { config }
export default {
  install(Vue) {
    Vue.appConfig = config
    Vue.feature = feature
    Vue.prototype.$appConfig = config
    Vue.prototype.$feature = feature
  },
}
