export const NewsStore = {
  namespaced: true,
  actions: {},
  state: {
    NewsDetail: null,
  },
  mutations: {
    getNewsDetail: (state, data) => {
      state.NewsDetail = data
    },
  },
  getters: {
    NewsDetail: state => state.NewsDetail,
  },
}
