export const AuthStore = {
  namespaced: true,
  actions: {},
  state: {
    token: null,
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token
      localStorage.setItem('token', token)
    },
    removeToken: state => {
      state.token = null
      localStorage.removeItem('token', null)
    },
  },
  getters: {
    token: state => state.token,
  },
}
